export const LABEL_CONSTANT = {
  CHANGE: $localize`:@@change:Change`,
  CHANGE_PASSWORD: $localize`:@@changePassword:Change Password`,
  CODE: $localize`:@@code:Code`,
  DESCRIPTION: $localize`:@@description:Description`,
  FORGOT_PASSWORD: $localize`:@@forgotPassword:Forgot Password`,
  LOADING_PLEASE_WAIT: $localize`:@@loadingPleaseWait:Loading, please wait...`,
  LOGIN: $localize`:Log in button@@loginButton:Login`,
  NEW_PASSWORD: $localize`:@@password:New Password`,
  NEXT: $localize`:Next button@@nextButton:Next`,
  ORGANIZATION: $localize`:@@Organization:organization`,
  PASSWORD: $localize`:@@password:Password`,
  PRIVACY_POLICY: $localize`:@@privacyPolicy:Privacy Policy`,
  CONFIRM_PASSWORD: $localize`:@@confirmPassword:Confirm Password`,
  RETURN_HOME: $localize`:@@returnHome:Return Home`,
  SELECT_ORGANIZATION: $localize`:@@selectOrganization:Select Organization`,
  SEND_CODE: $localize`:@@sendCode:Send Code`,
  SYSTEM_ACCESS_NOTIFICATION: $localize`:@@systemAccessNotification:System Access Notification`,
  TERMS_OF_USE: $localize`:@@termsOfUse:Terms of Use`,
  UPDATE: $localize`:@@update:Update`,
  USERNAME_EMAIL: $localize`:netsmartID@@usernameEmail:Username/Email`,
};

export const PLACEHOLDER_CONSTANT = {
  EMAIL_FORMAT: $localize`:@@emailFormat:user@domain.org`,
};

export const HTTP_ERROR_CONSTANT = {
  GENERAL_HTTP_ERROR: $localize`:unexpected Error@unexpectedError:An unexpected error occurred`,
  ORGANIZATION_NOT_FOUND: $localize`:Organization Not Found@@orgNotFound:We couldn't find your Organization.
    Please consult with your CareConnect administrator.`,
  ORGANIZATION_ERROR_PAGE: $localize`:Organization Error page@@orgErrorPage:We could not find your Organization.
    Please check with your CareConnect NX administrator for your expected login URL.`,
  UNAUTHORIZED_HTTP_ERROR: $localize`:unauthorized error@unauthorizedError:Unauthorized request`,
};

export const VALIDATION_ERROR_CONSTANT = {
  INVALID_EMAIL_ADDRESS: $localize`:Invalid Email Address@invalidEmailAddress:should be like`,
  IS_REQUIRED: $localize`:Is Required@@isRequired: is required`,
  PASSWORD_MISMATCH: $localize`:Password Mismatch@@passwordMissmatch:confirm password should match new password`,
};

export const GENERAL_ERROR_CONSTANT = {
  UNEXPECTED_ERROR: $localize`:Unexpected error@@unexpectedError:An unexpected error occurred. Please try again later.`,
};

export const PAGE_TITLE_CONSTANT = {
  FORGOT_PASSWORD: $localize`:Forgot password@@forgotPassword:Forgot Password`,
  LOGIN: $localize`:Page title Login@@loginPageTitle:Login`,
  ORGANIZATION_NOT_FOUND: $localize`:Page title organization name not found@@organizationNameNotFoundPageTitle:Login -
                                              Organization Name Not Found`,
  SELECT_ORGANIZATION: $localize`:Page title select organization@@selectOrgnizationPageTitle:Select Organization`,
};

export const ROUTE_CONSTANT = {
  DASHBOARD: 'dashboard',
  LOGIN: 'login',
  LOGIN_ORGANIZATION_NAME: 'login/organization/:orgId',
  ORGANIZATION_NOT_FOUND: 'organization-error',
  SELECT_ORGANIZATION: 'login/organization',
};

export const LOGIN_CONSTANT = {
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED: 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
};
export default LABEL_CONSTANT;
