import {Component, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NgClass} from '@angular/common';
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import LABEL_CONSTANT from '../../constant/constant';
import {LogoContainerComponent} from '../logo-container/logo-container.component';
import {HttpErrorService} from '../../core/services/http-error.service';

@Component({
  selector: 'app-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  imports: [FontAwesomeModule, NgClass, LogoContainerComponent],
})
export class CardComponent implements OnChanges {
  faCircleExclamation = faCircleExclamation;
  LABEL_CONSTANT = LABEL_CONSTANT;

  isDisplaySystemAccessNotification?: boolean;
  @Input('src') src: string = '../../../assets/images/netsmart-logo.png';
  @Input() subHeading: string = '';
  @Input() systemAccessNotification: string | undefined;

  constructor(public _httpErrorService: HttpErrorService) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isDisplaySystemAccessNotification =
      window.innerWidth > 650 && !!this.systemAccessNotification;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['systemAccessNotification']) {
      this.getScreenSize();
    }
  }
}
