import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() public label?: string;
  @Input() public disable = false;
  @Input() type = 'submit';
  @Input() className: 'primary-form-button' | 'primary-button' = 'primary-button';
}
